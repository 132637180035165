.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.result-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.result-card {
    background: #FFFFFF;
    box-shadow: 0px 2px 14px #E3E7F5;
    border-radius: 12px;
    padding: 45px 15px;
    width: 550px;
}

@media only screen and (max-width: 576px) {
    .result-card {
        width: 100%;
    }
}

.result-block-image {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.result-block-image p {
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5A6476;
}

.selphi-image {
    border: 1px solid black;
    border-radius: 35px;
    height: 130px;
    width: 130px;
}
.border-authentication{
    border-radius: 5px;
}

.icon-error {
    height: 40px;
    width: 40px;
}

.color-green {
    background: #D6F1DA;
}

.color-red {
    background: #FBD1DB;
}

.background-button-generate {
    background: #7636FC;
}

.background-button-authenticate {
    background: #3167FC;
}

.result-message {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    padding: 20px 100px;
    margin-bottom: 20px;
}

.result-card button {
    border-radius: 8px;
    padding: 12px 24px;
    color: #FFF;
    padding: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    width: 100%;
    text-transform: uppercase;
}

.result-card button img {
    margin-right: 10px;
}

.result-card button:hover {
    opacity: 0.8;
}

.result-main h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
}

.result-main h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 16px;
}