.div-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 32px;
    line-height: 44px;
    height: 80vh;
    text-align: center;
}

.div-error img{
    margin-bottom: 26px;
}

.div-error h2:first-letter{
    text-transform: uppercase;
}

.text-try-again{
    font-weight: 600;
}