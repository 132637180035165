.div-qr{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
} 


.div-qr-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 32px 32px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px #E3E7F5;
    border-radius: 12px;
}

.img-qr-code {
    max-width: 450px ;
    height: 100%;
}

@media only screen and (max-width: 576px) {
    .img-qr-code{
        max-width: 100%;
    }
}

.title{
    margin-top: 48px;
    height: 44px;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
}

.subtitle{
    height: 32px;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
}

.div-qr-card button {
    width: 100%;
    height: 48px;
    background: #7636FC;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #FFFFFF;
    font-weight: 600;
    border: none;
}

.div-qr-card a{
    width: 100%;
}

.div-qr-card button:hover{
    opacity: 0.8;
}

.div-qr-card button img{
    margin-right: 15px;
}
