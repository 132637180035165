.div-widget-base{
    min-height: 550px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.div-widget-base h2{
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    height: 44px;
    width: 30%;
    text-align: center;
}

.div-widget-base input{
    max-width: 500px;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    /* position: absolute;
    z-index: -1; */
}

.div-widget-base label{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    height: 95px;
    width: 30%;
    color: #1D2C4D;
    display: inline-block;
    background: #FFFFFF;
    opacity: 0.96;
    box-shadow: 0px 2px 14px #EAEEF6;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.div-widget-base label img{
    margin-right: 15px;
}

.div-widget-base p{
    font-weight: 400;
    font-size : 14px;
    line-height: 21px;
}

.div-widget-base button{
    text-transform: uppercase;
    background: #3167FC;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    color: white;
    font-weight: 600;
    border:none;
}

.div-widget-base button:disabled{
    background: lightgray;
    color: grey;
    cursor: not-allowed;
    
}

.div-widget-base button:hover{
    opacity: 0.8;
}


@media only screen and (max-width: 1400px) {
    .div-widget-base label{
        width: 50%;
    }
    .div-widget-base h2{
        width: 90%;
    }
}

@media only screen and (max-width: 920px) {
    .div-widget-base label{
        width: 70%;
    }
    .div-widget-base h2{
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .div-widget-base label{
        width: 90%;
    }
    .div-widget-base h2{
        font-size: 21px;
        line-height: 32px;
    }
}

@media only screen and (max-height: 650px) {
    .div-widget-base{
        min-height: 450px;
    }
}

@media only screen and (max-height: 590px) {
    .div-widget-base{
        min-height: 350px;
    }
}
