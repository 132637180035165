.div-loading {
    display:flex;
    justify-content: center;
    align-items: center;
    background: #F3F4F9;
    height: 80vh;
}

@keyframes rotating {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  .div-loading img {
    animation: rotating 4s linear infinite;
  }