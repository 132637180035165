.div-main{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F3F4F9;
    gap: 56px;
    margin-top: 10em;
}

/* Generate */
.div-generate{
    padding: 32px 32px 16px;
    box-shadow: 0px 2px 25px #EAEEF6;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 315px;
}

.div-generate h2 {
    margin-top: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
}


.div-generate p {
    font-size: 14px;
    text-align: center ;
}

.div-generate button {
    width: 100%;
    height: 48px;
    background: #7636FC;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #FFFFFF;
    font-weight: 600;
    border: none;
}

.div-generate button:hover{
    opacity: 0.8;
}

/* Authenticate */
.div-authenticate{
    padding: 32px 32px 16px;
    box-shadow: 0px 2px 25px #EAEEF6;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 315px;
}

.div-authenticate h2 {
    margin-top: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
}


.div-authenticate p {
    height: 42px;
    font-size: 14px;
    text-align: center ;
}

.div-authenticate button {
    width: 100%;
    height: 48px;
    background:#3167FC;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #FFFFFF;
    font-weight: 600;
    border: none;
}

.div-authenticate button:hover{
    opacity: 0.8;
}

@media only screen and (max-width: 576px) {
    .div-main{
        flex-direction: column;
    }
}
