.div-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 69px;
  background: #10192B;
  text-align: center;

  position: fixed;
  bottom: 0;
  width: 100%;
}

.div-stop {
  display: block;
  background: #F3F4F9;
  text-align: center;
  height: 69px;
  padding-top: 10px;
  position: fixed;
  bottom: 65px;
  width: 100%;
}

.div-stop button {
  border: 1px solid #DD3631;
  color: #DD3631;
  text-transform: uppercase;
  font-weight: 600;
}

.div-stop button:hover {
  border: 1px solid #DD3631;
  color: #DD3631;
  opacity: 0.6;
}

.hidden{
  display: none;
}

@media only screen and (max-width: 576px) {
    .div-footer {
      display: none;
    }

    .div-stop {
      position: fixed;
      bottom: 0;
    }
    
    .div-stop button{
      width: 90%;
    }
}